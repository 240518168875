.slides {
    width: 100%;

    & .slide {
        display: none;
        width: 100%;
        flex-direction: column;
        position: relative;

        &-active {
            opacity: 1;
            width: 100%;
            display: flex;
        }

        &__image {
            &-background {
                width: 100%;
                min-height: 220px;
                z-index: -2;

                @include breakpoint-lg() {
                    min-height: none;
                    height: 100vh;
                }
            }

            &-foreground {
                position: absolute;
                top: 2%;
                right: 10%;
                width: auto;
                max-height: 200px;

                @include breakpoint-md() {
                    max-height: 400px;
                    width: auto;
                }

                @include breakpoint-lg() {
                    // left: 15%;
                    right: 35%;
                    max-height: 700px;
                    width: auto;
                    z-index: 1;
                }
            }
        }

        &__content {
            position: relative;
            color: $white;
            padding: 1em;
            height: fit-content;
            background: $white;

            @include breakpoint-lg() {
                padding: 1em 4em 1em 2em;
                position: absolute;
                top: 25%;
                right: 0;
                max-width: 500px;
                min-height: 250px;
            }

            &-heading {
                font: $font-heading-mobile;
                margin-bottom: 0.5em;
                color: $brand;

                @include breakpoint-lg() {
                    font: $font-heading-desktop;
                }
            }

            &-text {
                font: $font-body-text-mobile;
                color: $grey-2;

                @include breakpoint-lg() {
                    font: $font-body-text-desktop;
                }
            }

            &-background {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: -1;
                height: 100%;
            }
        }
    }

    &__tabs {
        background: $grey-2;
        display: flex;
        width: 100%;
        justify-content: space-around;
        z-index: 2;
        cursor: pointer;

        @include breakpoint-lg() {
            background-color: transparent;
            position: absolute;
            bottom: 5%;
            left: 0;
        }

        & .tab {
            display: flex;
            flex: 1;
            justify-content: center;

            @include breakpoint-lg() {
                flex: 0;
                padding: 1em;
                min-width: 350px;
            }

            &:nth-child(2) {
                border-left: 0.5px solid $grey-1;
                border-right: 0.5px solid $grey-1;

                @include breakpoint-lg() {
                    border: none;
                }
            }

            &__content {
                display: none;

                @include breakpoint-lg() {
                    display: flex;
                    flex-direction: column;
                    padding: 1em 0 0 1em;
                    color: $white;
                }

                &-heading {
                    font: $font-heading-mobile;
                    margin-bottom: 0.5em;
                }

                &-text {
                    font: $font-body-text-mobile;
                }
            }

            &-active {
                background: $brand;
            }

            &__logo {
                display: flex;

                @include breakpoint-lg() {
                    align-items: flex-start;
                    margin-top: 1em;
                }

                img {
                    width: 60px;

                    @include breakpoint-lg() {
                        width: 90px;
                    }
                }
            }
        }
    }
}