@import "utils/variables";
@import "utils/breakpoints";
@import "utils/mixins";
@import "utils/hamburger";
@import "layouts/footer";
@import "layouts/header";
@import "components/slides";
@import "components/about";
@import "components/projects";
@import "components/section-3";
@import "components/contact";
@import "components/leigstungen";
@import "components/themen";
@import "components/tools";
@import "components/team";
@import "components/referenzen";
@import "components/content";
@import "components/applications";

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: $grey-2;
    @include breakpoint-lg(){
        font-size: 24px;
    }
}

h1, h2, h3, h4{
    margin: 0;
}

h1{ 
    font: normal 400 42px/1.5 'mono-rgo';
    @include breakpoint-lg(){
        font: normal 400 48px/1.5 'mono-rgo';
    }
}
h2{
    font: normal 400 24px/1.5 'mono-rgo';
    @include breakpoint-lg(){
        font: normal 400 36px/1.5 'mono-rgo';
    }
    color: $brand;
}
.hidden{
    display: none;
}
.back-to-top {
    position: fixed;
    bottom: 5%;
    right: 3%;
    z-index: 996;

    img {
        width: 15px;
    }
}
a{
    color: inherit;
    text-decoration: none;
}
.button{
    min-width: fit-content;
    a{
        color: $brand;
        text-transform: uppercase;
        font: $font-button-mobile;
        @include breakpoint-lg(){
            font: $font-button;
        }
    }
    &-arrow{
        width: 20px;
        margin-left: .5em;
    }
}

.quote-text{
    display: flex;
    flex-direction: column;
    font-family: $font-heading;
    font-weight: 600;
    gap: .5em;
    padding-right: 1em;
    &__author{
        font-size:18px;
        text-align: right;
    }
}

.bold{
    font-weight: 600;
}

.content{

    &-container{
        padding: 7em 2em;
       
    }

    &-list{
        display: flex;
        flex-direction: column;
        gap: .3em;
        padding: 1em;
        &__item{
            display: flex;
            align-items: center;
            gap: 1em;
            
        }
    }
}
#datenschutz{
    display: flex;
    align-items: center;
    min-height: 100vh;
}