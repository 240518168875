.tools{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
    // background:$gradiant;
    background: $brand;
    padding-top: 8vh;
    height: 30vh;
    color: $white;
    // align-items: center;
    @include breakpoint-lg(){
        padding: 5em 15em;
    }
    &__heading{
       
        & h2{
            color: $white;
        }
    }

}
.tools-content{
    img{
        width: 100%;
    }
    &__header{
        height: 50vh;
        position: relative;
        &-background{
            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }
        &-foreground{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            @include breakpoint-lg(){
                flex-direction: row;
                max-width: 1200px;
                justify-content: center;
                // align-items: center;
                margin: 0 auto;
            }
            
            &__logo{
                width: 200px;
                @include breakpoint-lg(){
                    width: 250px;
                }
                &.aem-logo{
                    width: 180px;
                @include breakpoint-lg(){
                   width: 300px;
                }
                }
            }
            &__image{
                padding: 0 1em;
                margin-top: 1em;
                img{
                    @include breakpoint-lg(){
                        width: 750px;
                    }
                }
               
            }
        }
    }
    &__text{
        display: flex;
        flex-direction: column;
        @include breakpoint-lg(){
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            max-width: 1200px;
            margin: 5em auto;
            font-size: 18px;
            // padding: 5em 0;
        }
    }
    &__tool{
        padding: 2em 2em .5em 2em;
        height: 100%;
        @include breakpoint-lg(){
            max-width: 450px;
        }
        &-heading{
            margin-bottom: 1em;
        }        
    }
    &__list{
        padding: 2em;
        @include breakpoint-lg(){
            padding: 3em 0 3em 5em;
        }
        &-item{
            display: flex;
            justify-content: center;
            align-items: start;
            margin-bottom: 1em;
            padding-right: 1.5em;
            font-weight: 500;
            i{
                margin-right: 1em;
                color: $brand;
                margin-top: 5px;
            }
        }

    }
}