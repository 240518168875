.content{
    display: flex;
    flex-direction: column;
    &__banner{
        img{
            width: 100%;
        }
    }
    &__text{
     padding: 2em;   
     margin-bottom: 4em;
     @include breakpoint-lg(){
         max-width: 1000px;
         margin: 0 auto 6em auto;
         background: $white;
         padding: 2em 4em;
     }
    }
    &__heading{
        margin-bottom: 1em;
    }
    &__link{
        display: flex;
        justify-content:flex-end;
        align-items: center;
        margin-top: 2em;
        flex-wrap: wrap;
        @include breakpoint-lg(){
            margin-top: 4em;
        }
        &-text{
            font-family: $font-heading;
            font-size: 18px;
            line-height: 1.5;
            padding: 0 1.5em;
            font-weight: 600;
            @include breakpoint-lg(){
                font-size: 20px;
                line-height: 24px;
                padding: 0 2em;

            }
        }
    }
}