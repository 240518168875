.header {
    background: $brand;
    color: $white;
    height: 100vh;
    margin-bottom: 10vh;

    @include breakpoint-lg {
        margin-bottom: 35vh;
    }

    &__heading {
        padding: 4em 2em 2em;
        max-width: 1200px;
        margin: auto;
    }

    &__text {
        padding: 0 2em;
        max-width: 1200px;
        margin: auto;

        span {
            max-width: 700px;
            display: block;
        }
    }

    &__navigation {
        display: flex;
        flex-wrap: wrap;
        margin: 3.5em auto 1em auto;
        justify-content: space-between;
        max-width: 300px;
        @include breakpoint-md(){
            max-width: 600px;
            // justify-content: space-evenly;
        }
        @include breakpoint-lg() {
            max-width: 1200px;
            margin: 3em auto;
        }
        &-index {
            position: relative;
            background: $grey-2;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-bottom: .2em;
            min-height: 70px;
            width: 49%;
            @include breakpoint-md(){
                max-width: 199px;
                min-height: 110px;
                margin-bottom: 2px;
                font-size: 20px;
            }
            @include breakpoint-lg() {
                min-height: 130px;
                min-width: 199px;
                max-width: 199px;
                margin-bottom: .5em;
                font-size: 24px;
            }
            &:hover {
                color: $brand;

                .header__navigation-index__number {
                    color: $brand;
                    opacity: 0.7;
                }
            }

            &__text {
                z-index: 52;
                flex-wrap: wrap;
                padding: 0 0.7em;
            }

            &__number {
                position: absolute;
                bottom: 0;
                right: 0;
                font-family: $font-heading;
                color: $grey-3;
                z-index: 50;
                font-size: 36px;
                // line-height: 32px;
                @include breakpoint-md() {
                    font-size: 60px;
                }
                @include breakpoint-lg() {
                    font-size: 96px;
                    line-height: 90px;

                }
            }
        }
    }

    &__image {
        margin: 0 2em;

        img {
            width: 80%;
            max-width: 600px;
            position: absolute;
            bottom: -10%;
            @include breakpoint-md(){
                max-width: 750px;
            }
            @include breakpoint-lg() {
                max-width: 950px;
                bottom: -25%;
                right: 25%;
            }
        }
    }
}

.list-content {
    margin: 1em 0;

    @include breakpoint-lg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        // margin: 4em;
        margin: 4em auto;
        max-width: 1200px;
    }

    &__heading {
        padding: 2em;
    }

    &__text {
        padding: 0 2em;

        @include breakpoint-lg {
            margin-bottom: 2em;
        }
    }

    &__skills {
        display: flex;
        flex-wrap: wrap;
        padding: 1em 2em;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;

        @include breakpoint-lg {
            font-size: 24px;
        }

        &-data {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            min-height: 220px;
            width: 48%;

            // margin-bottom: 1em;
            // border-bottom: 1px solid $grey-2;
            @include breakpoint-lg() {
                min-height: 250px;
            }

            &:nth-child(even) {
                border-left: 1px solid $grey-2;
            }

            &:nth-child(-n + 2) {
                border-bottom: 1px solid $grey-2;
            }

            img {
                max-width: 80px;
                min-height: auto;
                margin: auto;

                @include breakpoint-lg {
                    max-width: 100px;
                }
            }

            span {
                margin: 1em 0;
                padding: 0 0.5em;
            }
        }
    }

    &__bullet-list {
        margin: 2em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        &__data {
            display: flex;
            align-items: center;
            width: 45%;
            border-bottom: 1px solid $grey-2;
            min-height: 70px;
            // min-width: 300px;
            padding: 0 0.3em;

            &:nth-last-child(-n + 2) {
                border: none;
            }

            &:nth-child(odd) {
                border-right: 1px solid $grey-2;
            }

            // @include breakpoint-lg() {
            //     width: 45%;

            //     &:nth-last-child(-n + 3) {
            //         border: none;
            //     }

            //     &:nth-child(odd) {
            //         border-right: none;
            //     }

            //     &:nth-child(2),
            //     &:nth-child(5) {
            //         border-right: 1px solid $grey-2;
            //         border-left: 1px solid $grey-2;
            //     }
            // }
        }
    }
}