// COLORS
$brand: #ED5837;
$grey-1: #C4C4C4;
$grey-2: #403B41;
$grey-3: #333034;
$white: #fff;
$black: #000;
$gradiant: linear-gradient(180deg, $brand 5%, rgba(255, 161, 83, 0) 30%);

//Fonts
$font-heading: 'mono-rgo';
$font-body: 'mosk';
$font-footer-desktop: normal 600 24px/1.5 'mosk';
$font-footer-mobile: normal 600 16px/1.5 'mosk';
$font-button: normal 600 20px/24px 'mono-rgo';
$font-button-mobile: normal 600 18px/1.5 'mono-rgo';
$font-heading-desktop: normal 400 36px/1.5 'mono-rgo';
$font-heading-mobile: normal 400 24px/1.5 'mono-rgo';
$font-body-text-desktop: normal 500 18px/1.5 'mosk';
$font-body-text-mobile: normal 500 16px/1.5 'mosk';