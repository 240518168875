
body {
  &.no-scroll {
    height: 100vh;
    overflow: hidden;
  }
}


.navbar {
  position: fixed;
  width: 100vw;
   min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $brand;
  z-index: 997;
  box-shadow: 0 3px 5px -2px rgb(0 0 0 / 60%);

  &__logo {
    padding: 0 0.5em;
    text-decoration: none;

    &-icon {
      font-size: 32px;
      color: $white;
    }
  }

  &__links {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    padding: 50% 0 0 0;
    z-index: 998;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
    width: 60%;

    background-color: $grey-2;
    list-style-type: none;

    font-weight: 500;
    
    transform: translateX(100%);
    transition: 0.4s ease all;
    .nav-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: $white;
      text-decoration: none;
      
      &__header {
        text-decoration: none;
        color: $white;
        text-align: center;
        padding: 1em;
        width: 100%;
      }

      &__container{
        height: 0;
        transform-origin: top center;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .nav-column {
          &__heading {
            font-size: 18px;
            color: $white;
            text-decoration: none;
            padding-bottom: 0.5em;
          }

          &__subheading {
            display: none;
          }
        }

        &--shown {
          height: auto;
           -webkit-animation-name: rotateX;
           -webkit-animation-duration:0.5s;
           -webkit-animation-iteration-count: 1;
           -webkit-animation-timing-function: ease-in;
           -webkit-animation-fill-mode: forwards;
        }

        @-webkit-keyframes rotateX {
          0% {
            opacity: 0;
            transform: rotateX(-90deg);
          }

          50% {
            transform: rotateX(-20deg);
          }

          100% {
            opacity: 1;
            transform: rotateX(0deg);
          }
        }
      }

      &__button {
        margin-left: 2em;
        border: none;
        outline: none;
        padding: 0.75em 1em;
        margin: 1em 0;
        background: transparent;
        border: 2px solid $white;
        color: $white;
        font-size: 20px;
      }

      &--active {
        .nav-links__header {
        background-color: $brand;
        }
      }
    }
    &--active {
      box-shadow: 0 3px 1000px -2px rgb(0 0 0 / 60%);
      transform: translateX(0%);
    }
  }

  &__icon {
    display: block;
    outline: none;
    user-select:none;
    padding: 0 1em;

    &.is-active {
      position: fixed;
      right: 5%;
      z-index: 999;
    }
  }

  &--transparent {
    background-color: transparent;
    box-shadow: none;
  }
}

@include breakpoint-lg {
  .navbar {
    justify-content: center;
    align-items: flex-end;

    &__logo {
      &-icon {
        font-size: 64px;
        margin-right: 2em;
      }
    }

     &__links {
       position:relative;
       z-index: 998;
       transform: none;
       background: none;
       height: auto;
       padding: 0;
       margin: 0;
       width: auto;

       display: flex;
       flex-direction: row;

       .nav-links {
         margin: 0 1em;

         &__header {
           cursor: pointer;
         }

         &__container {
           display: flex;
           flex-direction: row;
           justify-content: space-evenly;
           align-items: flex-start;
           position: fixed;
           top: 8vh;
           left: 0;
           width: 100vw;
           background-color: $grey-2;
           .nav-column {
             padding: 2em 0;
             display: flex;
             flex-direction: column;
             flex-wrap: wrap;
             max-height: 200px;
             &__heading {
               padding: 1em;
               cursor: pointer;
               color: $brand;
               font-size: 20px;
               font-weight: 500;
               margin-bottom: 1em;
               text-align: left;
               padding: 0;
             }

             &__subheading {
               text-align: left;
               display: block;
               text-decoration: none;
               color: $white;
               font-size: 18px;
               margin: 0.5em 1em 0.5em 0;

               &:nth-child(5) {
                 margin-top: 3em;
               }
             }
           }
         }

          &__button {
            cursor: pointer;
            margin: 0;
          }

          &--active {
            .nav-links__header {
              background-color: $grey-2;
            }
          }
       }
     }

    

     &__icon {
       display: none;
     }


     &--transparent {
       .navbar__links {
         .nav-links{
           &--active {
             .nav-links__header {
               background-color: transparent;
               border-bottom: 2px solid $white;
             }
           }
         }
       }
     }
  }

}