.section-3 {
    @include breakpoint-lg {
        height: 100vh;
    }

    display: flex;
    flex-direction: column;
    width: 100%;

    &__image {
        width: 100%;
        order: 2;

        @include breakpoint-lg {
            position: absolute;
            z-index: -1;
            object-fit: cover;
            background-size: cover;
            min-width: 100%;
            min-height: 100%;
            max-height: 100vh;
        }

    }

    &__content {
        order: 1;
        background: $white;
        margin-top: auto;
        margin-bottom: 0;
        padding: 1em;

        @include breakpoint-lg {
            padding: 2em 3em;
            max-width: 800px;


        }

        &-subheading {
            font-weight: 700;
            margin-bottom: 1em;
            font-size: 18px;

            @include breakpoint-lg {
                font-size: 24px;
            }
        }

        &-heading {
            font: $font-heading-mobile;

            color: $brand;
            margin-bottom: .5em;

            @include breakpoint-lg {
                font: $font-heading-desktop;
            }
        }

        &-text {
            font: $font-body-text-mobile;

            @include breakpoint-lg {
                font: $font-body-text-desktop;
            }

            span {
                display: block;
                margin-bottom: 1em;
            }
        }
    }
}