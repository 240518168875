.application {
    &.selected{
        display: block;
    }
    &-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        max-width: 1200px;
        margin: 0 2em;
        font-size: 20px;
        @include breakpoint-lg(){
            flex-direction: row;
            margin: 0 auto;
            margin-bottom: 0em;
        }
        &__text{
            display: flex;
            flex: 0 1 40%;
            flex-direction: column;
            @include breakpoint-lg(){
                // flex-direction: row;
              
                // padding: 5em 0;
            }

            &-location{
                font-family: $font-heading;
            }

            &-description{
                margin-top: 2em;
            }
        }
        &__tool{
            padding: 2em 2em .5em 2em;
            height: 100%;
            @include breakpoint-lg(){
                max-width: 450px;
            }
            &-heading{
                margin-bottom: 1em;
            }        
        }
        &__list{
            flex: 0 1 60%;
            padding-top: 2em;

            @include breakpoint-lg(){
                padding: 1em 0 3em 5em;
            }
            &-item{
                display: flex;
                align-items: start;
                margin-bottom: 1em;
                font-weight: 500;
                @include breakpoint-lg(){
                    padding-right: 1.5em;

                }
                i{
                    margin-right: 1em;
                    color: $brand;
                    margin-top: 5px;
                }
            }
        }
       
    }
    .apply-for-position{
        margin: 1em auto 5em auto;
        max-width: 1200px;
        display: flex;
        align-items: center;
        gap: .5em;
        justify-content: center;
        @include breakpoint-lg(){
        justify-content: end;
        margin: 2em auto 6em auto;

        }
        a{
            font-size: 24px;
        }
        img{
            width: 20px;
        }
    }
    &-apply-form{
        display: flex;
        justify-content: space-between;
        // align-items: center;
        margin: 0 2em 5em 2em;
        @include breakpoint-lg(){
            max-width: 1200px;
            margin: 0em auto 5em auto;
        }
        
        input[type="file"] {
            display: none;
        }

        .contact-form{
            padding: 0;
            font-size: 20px;

            &__input{
                @include breakpoint-lg(){
                    width: 40%;
                }
            }

            &__buttons{
                display: flex;
                // flex-direction: column;
                width: 100%;
                align-items: flex-start;
                @include breakpoint-lg(){
                    justify-content: space-between;
                    width: 40%;
                }

            }
            &__button{
                // flex: 0 1 50%;
                margin: 0;
            }
        }
    }
}