.themen-header{
    height: 100vh;
    // background: $brand;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include breakpoint-lg(){
        flex-direction: row;
        justify-content: unset;
    }
    &__background{
        img{
            // @include cover-background();
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: -1;

        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        height: 100%;
        @include breakpoint-lg(){
            // background: $brand;
            flex-direction: row;
        }
    }
    &__text{
        background: $brand;
        // mix-blend-mode: multiply;
        padding: 2em;
        display: flex;
        height: inherit;
        flex-direction: column;
        justify-content: center;
        @include breakpoint-lg(){
            padding: 0 2em;
            margin-top: auto;
            margin-left: auto;
            height: 700px;
            width: 20%;
            align-items: flex-start;
            justify-content: start;
        }
        &-heading{
            margin-bottom: 2em;
            @include breakpoint-lg(){
                margin-top: 3em;
            }
            h2{
                color: $white;
            }
        }
    }
    &__contact{
        background: $grey-2;
        // mix-blend-mode: multiply;
        @include breakpoint-lg(){
            margin-top: auto;
            min-height: 700px;
            .contact{
                width: 700px;
            }
            .contact-form__input{
                width: 100%;
            }
            .contact-form__button{
                margin-right: 0;
            }
        }
        .contact-form__heading{
            font: $font-heading-mobile;
        }
       
        input{
            background: transparent;
        }
        input::placeholder {
            color: $white;
            opacity: 1; 
        }
        input:-ms-input-placeholder {
            color: $white;
          }
          
         input::-ms-input-placeholder {
            color: $white;
          }
    }
}
.themen-list{
    margin-bottom: 5em;
    &__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5em 0 1em 0;
        @include breakpoint-lg(){
            flex-direction: row;
            max-width: 1500px;
            margin: auto;
        }
    }
    
    .mega-ultra-special-class-for-order-because-nth-of-type-doesnt-fucking-work{
        @include breakpoint-lg(){
            order: 2;
        }
    }
    .list-image{
        img{
            width: 100%;
            max-height: 150px;
            @include breakpoint-md(){
                max-height: 200px;
            }
            @include breakpoint-lg(){
                max-height: unset;
                width: unset;
            }
        }
    }
    .list-content{
        @include breakpoint-lg(){
            max-width: 900px;
        }
    }
}
