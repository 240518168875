.about {
    position: relative;
    display: flex;
    flex-direction: column;

    @include breakpoint-lg {
        height: 100vh;
    }

    &__image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__content {
        background: $white;
        padding: 2em 1em;

        @include breakpoint-lg {
            position: absolute;
            left: 0;
            top: 10%;
            width: 40%;
            padding: 2em;
        }

        &-subheading {
            font: $font-body-text-desktop;
            font-weight: 700;
            margin-bottom: 1.5em;

            @include breakpoint-lg {
                font-size: 24px;
                margin-bottom: 1em;

            }

        }

        &-heading {
            font: $font-heading-mobile;
            color: $brand;
            margin-bottom: 1em;

            @include breakpoint-lg {
                font: $font-heading-desktop;

            }
        }

        &-text {
            font: $font-body-text-mobile;

            @include breakpoint-lg {
                font: $font-body-text-desktop;

            }
        }
    }
}