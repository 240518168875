.team{
    color: $white;
    &__background{
        img{
            object-fit: cover;
            position: absolute;
            height: 200%;
            width: 100%;
            z-index: -1;
            @include breakpoint-md(){
                height: 100%;
            }
            @include breakpoint-lg(){
                height: unset;
            }
        }
    }
    &__grid{
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        padding: 7em 2em;
        // justify-content: center;
        align-items: end;
        @include breakpoint-md(){
            padding: 7em 5em;
        }
        @include breakpoint-lg(){
            padding: 7em 0;
            gap: .2em;
            margin: 0 auto;
            width: 70%;
        }

    }
    &__text{
        color: $grey-2;
        background: transparent;
        margin-bottom: 2em;
        & h2{
            color: $grey-2;
        }
        @include breakpoint-md(){
            width: 60%;
            max-width: 360px;
            margin-bottom: 0;
        }
        @include breakpoint-lg(){
            width: 50%;
            padding: 1.5em;
            max-width: 500px;
        }
    }
    &__box{
        width: 40%;
        max-width: 150px;
        height: 180px;
        background: $white;
        color: $black;
        position: relative;
        padding: .3em .7em;
        z-index: 1;
        @include breakpoint-md(){
            width: 25%;
            max-width: 180px;
        }
        @include breakpoint-lg(){
            width: 25%;
            max-width: 250px;
            height: 270px;
        
        }
        &-name{
            font-size: 18px;
            font-weight: 600;
            z-index: 10;
            @include breakpoint-lg(){
               font-size: 20px;
            
            }

        }
        &-title{
            font-size: 14px;
            font-weight: 200;
            color: $grey-2;
            z-index: 10;
            @include breakpoint-lg(){
                font-size: 18px;
             
             }
        }
        &-image{
           img{
               width: 70%;
               max-height: 160px;
               max-width: 120px;
               position: absolute;
               right: 0;
               bottom: 0;
                z-index: -1;
                @include breakpoint-lg(){
                max-height: unset;
                max-width: unset;
               width: 60%;
                 
                 }

               
           }
        }
        &-property{
            background: transparent;
            color: $grey-2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            & h2{
                color: $grey-2;
            }
        }
    }
    .employee{
        width: 300px;
        height: 320px;
        background:$white;
    }
}