.footer {
  width: 100%;
  position: absolute;
  left: 0;
  background: #1d1d1d;
  color: $white;
  font: $font-footer-mobile;

  @include breakpoint-lg {
    font: $font-footer-desktop;
  }

  &-container {
    display: flex;
    flex-direction: column;
    @include breakpoint-lg(){
      flex-direction: row;
      align-items: center;
    }
  }

  &-logo {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey-2;
    padding: 1em;
    @include breakpoint-lg(){
      border: 0;
      margin-left: 2em;
    }
    img{
      width: 80px;
      @include breakpoint-lg(){
        width: 120px;
        margin-bottom: .5em;
      }
    }
    *{
      margin: 0 auto;

    }
  }

  &-links {
    display: flex;
    justify-content: space-around;
    align-items: start;
    padding: 2em 0;
    border-bottom: 1px solid $grey-2;
    @include breakpoint-lg(){
      border: 0;
      // margin: 0 auto;
      width: 700px;
      margin-left: auto;
      margin-right: 2em;
      font-size: 20px;
    }
    &__group{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      &-heading{
        margin-bottom: 1em;
        color: #696969;
        @include breakpoint-lg(){
          margin-bottom: .5em;
        }
      }
    }
  }
  &-copyright{
    display: flex;
    padding: .5em;
    background: #131313;
    @include breakpoint-lg(){
      font-size: 16px;
      padding-left: 1.5em;
    }
  }
}