.referenzen-header{
    height: 100vh;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &__background{
        img{
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: -1;

        }
    }
    &__text{
        display: flex;
        flex-direction: column;
        padding: 2em;
        margin: auto;
        @include breakpoint-lg(){
            max-width: 1000px;
            justify-content: center;
            // margin: 0 auto;
        }
        // align-items: center;
        &-heading{
            & h2{
                color: $white;
            }
        }
    }
    .header__navigation{
        // margin: 0;
        margin-bottom: 5em;
        @include breakpoint-lg(){
            gap: .2em;

        }
        &-index{
            min-height: 85px;
            height: 85px;
            @include breakpoint-lg(){
                min-height: 120px;
                height: 120px;
            }
            &__number{
                font-size: 60px;
                @include breakpoint-lg(){
                    font-size: 96px;
                }
            }
            display: block;
            &__logo{
                img{
                    width: 85px;
                    @include breakpoint-lg(){
                        width: 120px;
                    }

                }
            }
        }
    }
}

.client-profile{
    @include breakpoint-lg(){
        margin-bottom: 5em;
    }
    &__background{
        img{
            width: 100%;
            
        }
    }
    &__text{
        padding: 2em;
        @include breakpoint-lg(){
            max-width: 1000px;
            margin: 0 auto;
            // margin-top: -150px;
            background: $white;
        }
        &-heading{
            margin-bottom: 1em;
        }
    }

    &__projects{
        margin-bottom: 5em;
        @include breakpoint-lg(){
        margin-bottom: 0;

        }
        .project-active {
            @include breakpoint-lg {
            width: 100%;
            opacity: 1;
            }
            
        }
        .projects-content__project-preview{
            padding: 2em;
            @include breakpoint-lg {
                left: 0;
                bottom: 0;
                top: 40%;
            }
        }
    }
    &__table{
        @include breakpoint-lg(){
            margin-bottom: 4em;
        }
        .list-content{
            margin: 0 auto;
            max-width: 1200px;
        }
    }
    
}