.projects {
  @include breakpoint-lg {
    // height: 100vh;s
  }

  &-showreel {
    width: 100%;
    display: flex;
    flex-direction: column;
    // min-height: 220px;
    color: $white;
    background: #ED5837;

    @include breakpoint-lg {
      width: fit-content;
      // max-height: 300px;
      position: absolute;
      z-index: 3;
    }

    &__image {
      position: absolute;
      width: 100%;
      z-index: -1;

      @include breakpoint-lg {
        max-width: 550px;
        max-height: 100%;
      }
    }

    &__content {
      padding: .5em;

      &-heading {
        font: $font-heading-mobile;
        // margin-bottom: 0.5em;
        // color: $brand;

        @include breakpoint-lg {
          font: $font-heading-desktop;
        }
      }

      &-text {
        font: $font-body-text-mobile;

        @include breakpoint-lg {
          font-size: 24px;
        }
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;

    @include breakpoint-lg {
      flex-direction: row;
      height: 100vh;


    }

    &__project {
      @include breakpoint-lg {
        position: relative;
        width: 14%;
        opacity: .9;
        transition: width .5s ease-in-out;
        -webkit-transition: width .5s ease-in-out;
        -moz-transition: width .5s ease-in-out;
        -ms-transition: width .5s ease-in-out;
        -o-transition: width .5s ease-in-out;

        &:hover {
          width: 17%;
          opacity: 1;
        }
      }

      &.project-active {
        @include breakpoint-lg {
          width: 60%;
          opacity: 1;

          .projects-content__project-preview {
            opacity: 1;
            transition: all .5s;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -ms-transition: all .5s;
            -o-transition: all .5s;
          }
        }
      }

      &-image {
        width: 100%;
        min-width: 200px;

        @include breakpoint-lg {
          width: 100%;
          height: 100%;
          background-color: #cccccc;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          object-fit: cover;
        }
      }
      &-text{
        display: none;
        @include breakpoint-lg(){
          display: block;
          position: absolute;
          bottom: 5%;
          left: 10%;
          color: $white;
          width: 100px;
          z-index: 9999;
          font-size: 18px;
        }
        
      }
      &-logo {
        display: none;

        @include breakpoint-lg {
          display: block;
          position: absolute;
          z-index: 3;
          bottom: 10%;
          left: 30%;
          width: 100px;
        }

      }

      &-preview {
        background: $white;
        padding: 1em;

        @include breakpoint-lg {
          opacity: 0;
          position: absolute;
          max-width: 350px;
          right: 0;
          top: 30%;
        }

        .project-title {
          margin-bottom: 1em;
          font: $font-body-text-desktop;
          font-weight: 600;
        }

        .project-description {
          margin-bottom: 1em;
          font: $font-body-text-mobile;
        }

        .project-highlight {
          font: $font-heading-mobile;
          color: $brand;

          @include breakpoint-lg {
            font: $font-heading-desktop;
          }
        }
      }
    }
  }
}