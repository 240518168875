$md: 768;
$lg: 1024;
$xl: 1280;

@mixin breakpoint-md {
  @media only screen and (min-width: $md * 1px) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media only screen and (min-width: $lg * 1px) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media only screen and (min-width: $xl * 1px) {
    @content;
  }
}