.contact {
    width: 100%;
    height: 100%;

    @include breakpoint-lg {}

    &-container {
        height: inherit;
        @include flex-column();

        @include breakpoint-lg {
            flex-direction: row;
        }
    }

    &-form {
        padding: 1em 2em;
        @include flex-column();
        justify-content: space-evenly;
        align-items: start;

        @include breakpoint-lg{
            flex: 1 100%;
        }

        &__heading {
            margin: 1em 0;

            @include breakpoint-lg {
                margin: 2em 0;
            }
        }

        &__input {
            border: none;
            border-bottom: 3px solid $grey-1;
            width: 100%;
            margin-bottom: 2em;
            padding: 1em 0;
            outline: none;
            font-family: $font-body;

            @include breakpoint-lg {
                width: 60%;
                font-size: 20px;
                padding: .5em 0em;
            }
        }

        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            flex-wrap: wrap;

            @include breakpoint-lg {
                margin-right: auto;
            }

            img {
                width: 20px;
            }
        }

        &__submit {
            background: transparent;
            border: none;
            font: $font-button;
            color: $brand;
            outline: none;

        }
    }

    &-image {
        height: 40vh;
        @include breakpoint-lg{
            flex: 1 50%;
            height: auto;
        }

        &__img {
            width: 100%;
            height: 40vh;
            @include breakpoint-lg {
                height: 100%;
            }
        }
    }
}